import * as React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => {
    return (
        <Layout pageTitle="404">
            <div className="px-5">
                <h1>404: Not Found</h1>
                <p>Well... that's interesting...</p>
            </div>
        </Layout>
    )
}

export default NotFoundPage
